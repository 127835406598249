import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Modal,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import LogForm from './LogForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlanning } from 'store/planningSlice';
import { selectUser } from 'store/userSlice';
import { fetchLessonLog, selectLogData } from 'store/lessonLogSlice';
import { showMessage } from '../../../store/messageSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '95%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};

const MentorLessonLog = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const logData = useSelector(selectLogData);

  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [detail, setDetail] = useState(null);
  const [logId, setLogId] = useState(null);

  const handleEditLog = (item) => {
    setDetail(item);
    setModalShow(true);
    setIsEdit(true);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;
      try {
        const lessonLogResponse = await dispatch(fetchLessonLog(user.uid)).unwrap();
        if (lessonLogResponse) {
          const logs = Object.keys(lessonLogResponse[0])
            .filter((key) => !isNaN(key))
            .map((key) => ({
              ...lessonLogResponse[0][key],
              id: Number(key),
            }));
          setData(logs);
          setLogId(lessonLogResponse[0]?.docId);
        }
        const planningResponse = await dispatch(fetchPlanning(user.uid)).unwrap();
        if (planningResponse) {
          const lessonsArray = Object.keys(planningResponse.lessons).map((key) => ({
            ...planningResponse.lessons[key],
            id: Number(key),
          }));
          setLessons(lessonsArray);
        }
      } catch (error) {
        dispatch(showMessage({ message: '데이터를 불러오지 못했습니다. CODE: ML001', variant: 'error' }));
        console.error(error);
      }
    };
    fetchUserData();
  }, [dispatch, user]);

  return (
    <Box className="flex flex-col justify-start px-10 py-10 my-4 w-full max-w-5xl bg-white rounded-2xl">
      <Typography variant="h2" className="pb-2">수업일지</Typography>
      <Typography className="!font-normal pb-2">
        매 회차 수업을 진행한 이후 일지를 작성해주세요.
      </Typography>
      <Divider />
      <Box className="flex flex-col px-4 pt-4 gap-2">
        <Button color="error" onClick={() => setModalShow(true)}>+ 일지 작성하기</Button>
        {data.map((item) => (
          <Card sx={{ minWidth: 275, p: 2, mb: 1 }} key={item.id} className="flex justify-between">
            <CardContent>
              <Box className="flex">
                <Typography>{item.lessonTerm}</Typography>
                <Typography className="pl-4">{item.lessonDate}</Typography>
              </Box>
              <Typography>
                수업진도: <Typography component="span">{item.title}</Typography>
              </Typography>
              <Box className="flex">
                <Typography>학습온도: <Typography component="span">{item.lessonTemp}</Typography></Typography>
                <Typography className="pl-4">관계온도: <Typography component="span">{item.relationTemp}</Typography></Typography>
              </Box>
              <Typography>
                특이사항: <Typography component="span">{item.note}</Typography>
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="error" onClick={() => handleEditLog(item)}>수정하기</Button>
            </CardActions>
          </Card>
        ))}
      </Box>
      <Modal
        open={modalShow}
        onClose={() => setModalShow(false)}
        aria-labelledby="logComponentTitle"
        aria-describedby="logComponentTitleDescription"
      >
        <Box sx={{ ...style }}>
          <LogForm
            docId={logId}
            setDocId={setLogId}
            uid={user?.uid}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            planningData={lessons}
            onClose={setModalShow}
            data={detail}
            setData={setDetail}
            logData={data}
            setLogData={setData}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default MentorLessonLog;
