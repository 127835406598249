import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import 'dayjs/locale/ko';
import { useDispatch, useSelector } from 'react-redux';
import { editLessonLog, saveLessonLog } from 'store/lessonLogSlice';
import { showMessage } from 'store/messageSlice';
import { getUserApplications } from 'store/userSlice';

const LogForm = ({
  docId,
  setDocId,
  uid,
  isEdit,
  setIsEdit,
  planningData,
  onClose,
  data,
  setData,
  logData,
  setLogData,
}) => {
  const dispatch = useDispatch();
  const applications = useSelector(getUserApplications);
  const title = isEdit ? '수업일지 수정하기' : '수업일지 작성하기';
  const terms = Array.from({ length: 12 }, (_, i) => i + 1);
  const marks = [
    { value: 1, label: '1' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
  ];
  const [date, setDate] = useState(dayjs(new Date()));
  const [term, setTerm] = useState(terms[0]);
  const [checked, setChecked] = useState(false);
  const [lessonTemp, setLessonTemp] = useState(1);
  const [relationTemp, setRelationTemp] = useState(1);
  const [lesson, setLesson] = useState('');
  const [status, setStatus] = useState('new');
  const [aid, setAid] = useState(null);

  const handleChangeTerm = (event) => {
    setTerm(event.target.value);
  };

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeTemp = (type, newValue) => {
    if (type === 'lesson') {
      setLessonTemp(newValue);
    } else {
      setRelationTemp(newValue);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!logData) {
      dispatch(showMessage({ message: '데이터를 조회하지 못했습니다. 관리자에게 문의해주세요. CODE: ML002', variant: 'error' }));
      return;
    }

    if (status !== 'edit' && logData?.find((item) => item.id === term)) {
      dispatch(showMessage({ message: '이미 작성한 회차입니다.', variant: 'error' }));
      return;
    }

    const form = new FormData(event.currentTarget);
    const data = Object.fromEntries(form.entries());
    data.lessonDate = dayjs(date).format('YYYY-MM-DD');
    data.lessonTerm = term + '회차';
    data.lessonTemp = lessonTemp;
    data.relationTemp = relationTemp;
    data.title = lesson;

    if (status === 'new') {
      dispatch(saveLessonLog({ data, uid, term, aid }))
        .then((res) => {
          if (res.payload) {
            setDocId(res.payload);
            dispatch(showMessage({ message: '수업일지가 작성되었습니다.', variant: 'success' }));
            const updateData = { ...data, id: term };
            setLogData([updateData]);
          }
        })
        .then(() => {
          onClose(false);
        });
    } else if (status === 'add') {
      dispatch(saveLessonLog({ data, uid, term, aid, docId }))
        .then((res) => {
          dispatch(showMessage({ message: '수업일지가 작성되었습니다.', variant: 'success' }));
          const updateData = { ...data, id: term };
          setLogData((prev) => [...prev, updateData]);
        })
        .then(() => {
          onClose(false);
        });
    } else if (status === 'edit') {
      dispatch(editLessonLog({ data, docId, term }))
        .then((res) => {
          dispatch(showMessage({ message: '수업일지가 수정되었습니다.', variant: 'success' }));
          const updateData = { ...data, id: term };
          setLogData((prev) => {
            const index = prev.findIndex((item) => item.id === term);
            prev[index] = updateData;
            return [...prev];
          });
        })
        .then(() => {
          onClose(false);
        });
    }
  };

  useEffect(() => {
    if (planningData) {
      setAid(applications[0].aid);
      const lesson = planningData?.find((item) => item.id === term);
      if (lesson) {
        setLesson(lesson.title);
        setChecked(true);
      } else {
        setLesson('');
        setChecked(false);
      }
    }
  }, [planningData, term]);

  useEffect(() => {
    if (logData) {
      setStatus('add');
      const addTerm = terms.find((item) => !logData.find((log) => log.id === item));
      setTerm(addTerm);
    }
  }, [logData]);

  useEffect(() => {
    if (data) {
      setDate(dayjs(data.lessonDate));
      setTerm(parseInt(data.lessonTerm));
      setLessonTemp(data.lessonTemp);
      setRelationTemp(data.relationTemp);
      setLesson(data.title);
      setStatus('edit');
    }

    return () => {
      setStatus('new');
      setIsEdit(false);
      setData(null);
    };
  }, [data]);

  useEffect(() => {
    if (isEdit) {
      setStatus('edit');
      const lesson = planningData?.find((item) => item.id === term);
      if (lesson.title === data.title) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [isEdit]);

  return (
    <Box className="flex flex-col h-full ">
      <Box>
        <Box className="flex justify-between items-cente">
          <Typography className="!font-semibold" variant="h5">
            {title}
          </Typography>
          <CloseIcon className="cursor-pointer" fontSize="medium" onClick={() => onClose(false)} />
        </Box>
        <Typography className="!font-normal pt-2">
          매 회차 수업을 진행한 이후 작성해주세요.
        </Typography>
      </Box>
      <Divider className="p-3" />
      <Grid
        container
        rowGap={3}
        columnSpacing={3}
        className="p-6 h-[80vh] overflow-y-auto "
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid item xs={6}>
          <Typography className="text-left !font-semibold">수업일</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker
                value={date}
                onChange={(newValue) => setDate(newValue)}
                InputProps={{ color: 'error' }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Typography className="text-left !font-semibold pb-2">수업 회차</Typography>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={term}
              onChange={handleChangeTerm}
              inputProps={{
                'aria-label': 'Without label',
                className: 'flex items-center',
                color: 'error',
              }}
            >
              {terms.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {`${item}회차`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography className="text-left !font-semibold pb-1">수업진도</Typography>
          <Typography className="text-left !text-sm text-gray-600 !font-extralight pb-3">
            초기 계획과 달라진 부분이 있다면 체크박스를 해제하고 내용을 작성해주세요.
          </Typography>
          <TextField
            id="title"
            name="title"
            variant="outlined"
            InputProps={{ className: 'p-1', readOnly: checked }}
            value={lesson}
            onChange={(e) => setLesson(e.target.value)}
            hiddenLabel
            fullWidth
            size="small"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChangeCheck}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="변동 사항 없음"
            sx={{
              '& .MuiCheckbox-root': { pr: 0 },
              '& .MuiTypography-root': { fontSize: '0.875rem' },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className="text-left !font-semibold pb-1">학습온도</Typography>
          <Typography className="text-left !text-sm text-gray-600 !font-extralight pb-2">
            멘티의 수업 참여도와 학습 진행 상황을 1 에서 10 사이의 숫자로 입력해주세요!
            <br />
            점수가 높을수록 <span className="text-sky-500">'적극적'</span>인 학습온도, 낮을수록{' '}
            <span className="text-sky-500">'소극적'</span>인 학습온도입니다.
          </Typography>
          <Box sx={{ width: 420, pt: 5 }}>
            <Slider
              min={1}
              max={10}
              step={1}
              marks={marks}
              value={lessonTemp}
              onChange={(e, newValue) => handleChangeTemp('lesson', newValue)}
              valueLabelDisplay="on"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography className="text-left !font-semibold pb-1">관계온도</Typography>
          <Typography className="text-left !text-sm text-gray-600 !font-extralight pb-2">
            멘토와 멘티의 수업 시 친밀도를 1 에서 10 사이의 숫자로 입력해주세요!
            <br />
            점수가 높을수록 <span className="text-sky-500">'적극적'</span>인 관계온도, 낮을수록{' '}
            <span className="text-sky-500">'소극적'</span>인 관계온도입니다.
          </Typography>
          <Box sx={{ width: 420, pt: 5 }}>
            <Slider
              min={1}
              max={10}
              step={1}
              marks={marks}
              value={relationTemp}
              onChange={(e, newValue) => handleChangeTemp('relation', newValue)}
              valueLabelDisplay="on"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography className="text-left !font-semibold pb-1">특이사항</Typography>
          <Typography className="text-left !text-sm text-gray-600 !font-extralight pb-2">
            건의가 필요한 사항이나 수업에 관한 전반적인 특이사항을 입력해주세요 :)
          </Typography>
          <TextField
            id="note"
            name="note"
            variant="outlined"
            InputProps={{ className: 'p-1' }}
            placeholder=""
            defaultValue={data?.note || ''}
            hiddenLabel
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit">저장</Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogForm;
