import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TextField,
  TableSortLabel, // Import TableSortLabel for sorting
} from '@mui/material';

const LessonLogList = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState(''); // State to track search input
  const [sortOrder, setSortOrder] = useState('asc'); // State to track sorting order
  const [sortColumn, setSortColumn] = useState(null); // Track which column is being sorted

  if (!data || data.length === 0) {
    return <Typography>No lesson logs available.</Typography>;
  }

  // Extract all the lesson term keys
  const lessonColumns = Array.from({ length: 12 }, (_, i) => `${i + 1}회차`);

  // Filter and sort data based on search term and sorting order
  const filteredData = data
    .filter((item) =>
      item.application.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortColumn === '수업계획') {
        if (sortOrder === 'asc') {
          return (a.plannings ? 1 : -1) - (b.plannings ? 1 : -1);
        } else {
          return (b.plannings ? 1 : -1) - (a.plannings ? 1 : -1);
        }
      } else if (sortColumn) {
        const idx = parseInt(sortColumn); // 회차(1~12)를 정렬 기준으로 사용
        const aLesson = a.lessonLogs?.[0]?.[`${idx}`] ? 1 : -1;
        const bLesson = b.lessonLogs?.[0]?.[`${idx}`] ? 1 : -1;
        return sortOrder === 'asc' ? aLesson - bLesson : bLesson - aLesson;
      }
      return 0; // 기본 상태에서 정렬하지 않음
    });

  const handleSortClick = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sort order
    } else {
      setSortColumn(column); // Set the new column for sorting
      setSortOrder('asc'); // Default to ascending when a new column is sorted
    }
  };

  return (
    <Box>
      {/* Search box above the table */}
      <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" style={{ marginRight: '20px', width: '500px' }}>
          진행중인 수업 수: {filteredData.length}
        </Typography>
        <TextField
          label="Search by Name"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
        />
      </Box>

      {/* Table container with horizontal scroll */}
      <TableContainer component={Paper} style={{ overflowX: 'auto', maxHeight: '400px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {/* Add TableSortLabel to allow sorting by "수업계획" column */}
              <TableCell style={{ whiteSpace: 'nowrap' }}>멘토이름</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>멘토이메일</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={sortColumn === '수업계획'}
                  direction={sortOrder}
                  onClick={() => handleSortClick('수업계획')} // Handle click to toggle sorting
                >
                  수업계획
                </TableSortLabel>
              </TableCell>
              {lessonColumns.map((col, idx) => (
                <TableCell key={col} style={{ whiteSpace: 'nowrap' }}>
                  <TableSortLabel
                    active={sortColumn === `${idx + 1}`} // Check if the current column is being sorted
                    direction={sortOrder}
                    onClick={() => handleSortClick(`${idx + 1}`)} // Sort by the current 회차 column
                  >
                    {col}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((item, index) => {
              const lessonLogs = item.lessonLogs; // Access the lesson logs
              return (
                <TableRow key={index}>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{item.application.name}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{item.application.email}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{item.plannings ? 'O' : 'X'}</TableCell>
                  {lessonColumns.map((_, idx) => (
                    <TableCell key={idx} style={{ whiteSpace: 'nowrap' }}>
                      {lessonLogs && lessonLogs[0] && lessonLogs[0][`${idx + 1}`] ? 'O' : 'X'}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LessonLogList;
