import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from 'firebase_config';
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  limit,
} from 'firebase/firestore';

/// 배남프 현재 기수 정보
const GENERATION = 6;

const initialState = {
  data: null,
  docId: null,
};

/**
 * [ADMIN] 모든 lessonLog를 조회합니다.
 */
export const fetchLessonLogWithApplication = createAsyncThunk(
  'lessonLog/fetchLessonLogWithApplication',
  async () => {
    try {
      // Step 1: Fetch applications with generation 6 and matchingStatus 2
      const applicationFetchRef = collection(db, 'Applications');
      const applicationQueryRef = query(
        applicationFetchRef,
        where('generation', '==', GENERATION),
        where('matchingStatus', '==', 2),
        where('userType', '==', 'mentor')
      );
      const applicationSnapshot = await getDocs(applicationQueryRef);

      if (applicationSnapshot.empty) {
        console.log('No applications found.');
        return null;
      }

      let logData = [];
      for (const appDoc of applicationSnapshot.docs) {
        const applicationData = appDoc.data();
        const aid = applicationData.aid;

        // Step 2: Fetch corresponding LessonLogs
        const lessonLogFetchRef = collection(db, 'LessonLogs');
        const lessonLogQueryRef = query(lessonLogFetchRef, where('aid', '==', aid));
        const lessonLogSnapshot = await getDocs(lessonLogQueryRef);

        // Step 3: Fetch corresponding Plannings
        const planningFetchRef = collection(db, 'Plannings');
        const planningQueryRef = query(planningFetchRef, where('aid', '==', aid));
        const planningSnapshot = await getDocs(planningQueryRef);

        let lessonLogData = lessonLogSnapshot.empty ? null : lessonLogSnapshot.docs.map(doc => doc.data());
        let planningData = planningSnapshot.empty ? null : planningSnapshot.docs.map(doc => doc.data());

        // Step 4: Combine application, lesson log, and planning data
        logData.push({
          application: applicationData,
          lessonLogs: lessonLogData,
          plannings: planningData,
          docId: appDoc.id, // Reference to the application document
        });
      }

      console.log("LESSON LOG WITH APPLICATION:", logData);
      return logData;
    } catch (error) {
      console.error("Error fetching lesson logs and plannings:", error);
      throw error; // Ensure the error is thrown to be handled by Redux
    }
  }
);


/**
 * [USER] 사용자를 위한 API -> 특정 유저의 log를 가져옵니다.
 */
export const fetchLessonLog = createAsyncThunk('lessonLog/fetchLessonLog', async (uid) => {
  const fetchRef = collection(db, 'LessonLogs');
  const queryRef = query(
    fetchRef,
    where('uid', '==', uid),
    where('generation', '==', GENERATION)
  );
  const querySnapshot = await getDocs(queryRef);
  try {
    if (querySnapshot.empty) {
      console.error("데이터가 없습니다.")
      return null;
    }
    const logData = []
    for (const doc of querySnapshot.docs) {
      if (doc.exists()) {
        logData.push({
          ...doc.data(),
          docId: doc.id,
        });
        return logData
      }
    }
    return null;
  } catch (err) {
    console.error(err);
  }
});

// export const saveLessonLog = createAsyncThunk(
//   'lessonLog/saveLessonLog',
//   async ({ data, uid, term, aid }) => {
//     try {
//       const newData = {
//         [term]: { ...data, createdAt: serverTimestamp() },
//         uid,
//         aid,
//         generation: GENERATION,
//       };
//       const docRef = doc(collection(db, 'LessonLogs'));
//       await setDoc(docRef, newData);

//       return docRef.id;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const saveLessonLog = createAsyncThunk(
  'lessonLog/saveOrUpdateLessonLog',
  async ({ data, uid, term, aid, docId }) => {
    try {
      const newData = {
        [term]: { ...data, createdAt: serverTimestamp() },
        uid,
        aid,
        generation: GENERATION,
      };

      if (docId) {
        // docId가 존재하면 업데이트
        const updatedData = { [term]: { ...data, createdAt: serverTimestamp() } };
        await updateDoc(doc(db, 'LessonLogs', docId), updatedData);
        return { docId, updatedData };
      } else {
        console.log("존재하지 않는 데이터. 새로 추가")
        // docId가 없으면 새로운 문서 생성
        const docRef = doc(collection(db, 'LessonLogs'));
        await setDoc(docRef, newData);
        return { docId: docRef.id, newData };
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateLessonLog = createAsyncThunk(
  'lessonLog/updateLessonLog',
  async ({ data, docId, term }) => {
    try {
      const newData = { [term]: { ...data, createdAt: serverTimestamp() } };
      const docRef = await updateDoc(doc(db, 'LessonLogs', docId), newData);

      return { docRef, newData };
    } catch (error) {
      throw error;
    }
  }
);

export const editLessonLog = createAsyncThunk(
  'lessonLog/editLessonLog',
  async ({ data, docId, term }) => {
    try {
      const newData = { [term]: { ...data, updatedAt: serverTimestamp() } };
      const docRef = await updateDoc(doc(db, 'LessonLogs', docId), newData);

      return { docRef, newData };
    } catch (error) {
      throw error;
    }
  }
);

const lessonLogSlice = createSlice({
  name: 'lessonLog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLessonLogWithApplication.fulfilled, (state, action) => {
        state.data = action.payload; // Store the data with application information
      })
      .addCase(saveLessonLog.fulfilled, (state, action) => {
        state.docId = action.payload;
      })
      .addCase(fetchLessonLog.fulfilled, (state, action) => {
        state.data = action.payload;
      })
  },
});

export const selectLogData = (state) => state.lessonLog.data;

export default lessonLogSlice.reducer;
