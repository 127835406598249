import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Tabs, Tab } from '@mui/material';
import LessonLogList from './LessonLogList'; // Component to display lesson logs
import { useDispatch, useSelector } from 'react-redux';
import { fetchLessonLogWithApplication, selectLogData } from 'store/lessonLogSlice'; // Import fetchAllLessonLogs

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const LessonLogAdminPage = () => {
  const dispatch = useDispatch();
  const lessonLogs = useSelector(selectLogData); // Select lesson logs from the store
  const [value, setValue] = useState(0);

  // Dispatch the fetchAllLessonLogs action when the first tab is selected
  useEffect(() => {
    if (value === 0) {
      dispatch(fetchLessonLogWithApplication()); // Fetch all lesson logs for admins
    }
  }, [dispatch, value]);

  // Log lessonLogs when it changes
  useEffect(() => {
    console.log("LESSON LOG 조회:", lessonLogs);
  }, [lessonLogs]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Lesson Logs Admin
        </Typography>
        <Tabs value={value} onChange={handleTabChange} aria-label="admin page tabs">
          <Tab label="All Lesson Logs" />
          <Tab label="User Lesson Log" />
        </Tabs>
        <TabPanel value={value} index={0}>
          {lessonLogs ? (
            <LessonLogList data={lessonLogs} /> // Display all logs for admins
          ) : (
            <Typography>Loading...</Typography>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* This is where you can load logs for a specific user */}
          <Typography>Here you can load user-specific lesson logs.</Typography>
        </TabPanel>
      </Box>
    </Container>
  );
};

export default LessonLogAdminPage;
